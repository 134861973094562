import { FileModel } from '../domain/files/File';

export type Model = {
  id: FileModel;
  title: string;
  label: string;
  description: string;
  isNew: boolean;
  shortName?: string;
};

export const FileModels: Model[] = [
  {
    id: 'Play3.0',
    title: 'Play 3.0 mini',
    label: 'Multilingual',
    description: 'Featuring enhanced alpha-numeric readability, multilingual support in 36 languages.',
    isNew: true,
    shortName: '3.0 mini',
  },
  {
    id: 'PlayHT2.0-gargamel',
    title: 'PlayHT 2.0 Gargamel',
    label: 'English Only',
    description:
      'Our latest version of PlayHT 2.0 with higher quality, reliability and better voice and accent cloning.',
    isNew: false,
    shortName: '2.0 G',
  },
  {
    id: 'PlayHT2.0',
    title: 'PlayHT 2.0',
    label: 'English Only',
    description: 'Our second generation voice model built for Conversational Speech and Emotions.',
    isNew: false,
    shortName: '2.0',
  },
  {
    id: 'PlayHT1.0',
    title: 'PlayHT 1.0',
    label: 'English Only',
    description:
      'Our first voice model built for generating human-like speech. Offers a large library of pre-built voices to choose from.',
    isNew: false,
    shortName: '1.0',
  },
  {
    id: 'Standard',
    title: 'Standard',
    label: '100+ languages',
    description:
      'Multi-lingual text to speech voices with consistent outputs. Choose from 900+ voices across 100+ languages that support pauses, emphasis, tones and pronunciations.',
    isNew: false,
  },
];
