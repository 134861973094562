import axios from 'axios';
import { User as FirebaseUser } from '@firebase/auth';
import { v4 as uuid } from 'uuid';
import { CONFIG } from '../config.ts';
import { firestoreDB } from '../utils/firebase.ts';
import { User } from '../contexts/user.context.tsx';
import { File, FileModel } from '../domain/files/File.ts';
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore';
import { QuerySnapshot, DocumentData } from 'firebase/firestore';

const playApi = axios.create({ baseURL: CONFIG.playAPIBaseUrl });
const playStudioApi = axios.create({ baseURL: CONFIG.playStudioAPIBaseUrl });

// TODO: validate file shape with Zod
export function listenUserFiles(userId: string, onChange: (snapshot: QuerySnapshot<DocumentData>) => void) {
  const filesQuery = query(
    collection(firestoreDB, 'studio-files-metadata'),
    where('userId', '==', userId),
    limit(1000)
  );
  return onSnapshot(filesQuery, onChange);
}

// TODO: get user from zustand store
// TODO: implement new endpoint to create file
//   This endpoint is from play backend, which has mixed logic between old files and kettle files, so there are some
//   unnecessary fields in the request body that currently we need to send to work properly.
export async function createFile(user: User, model: FileModel = 'PlayHT2.0-gargamel') {
  const url = '/createNewArticle';
  const body = {
    url: `https://play.ht/drafts/${user.id}/${uuid()}`,
    userId: user.id,
    appId: user.appId,
    author_name: user.name ?? user.email,
    voice: 'en-US-JennyNeural',
    isStudio: true,
    isStudioAudio: true,
    model,
  };
  // TODO: add Zod to validate response
  const response = await playApi.post<{ articleData: File }>(url, body);
  return response.data.articleData;
}

export async function deleteFile(user: FirebaseUser, fileId: string) {
  const url = '/deleteStudioArticle';
  const idToken = await user.getIdToken();
  const body = {
    token: idToken,
    articleId: fileId,
    isDraft: false, // TODO: is it really necessary?
    isAudioStudio: true,
  };
  await playApi.delete(url, { data: body });
  await playStudioApi.delete(`/kettle/recent-files/${fileId}`, { data: { token: idToken } });
}
