/* eslint-disable react-hooks/exhaustive-deps */
import { entries, fromEntries, keys } from '../utils/objectUtils.ts';
import { useFeatureFlag } from 'configcat-react';
import { useMemo } from 'react';
import { useAuth } from '../contexts/auth.context.tsx';
import { User as ConfigCatUser } from 'configcat-common/lib/RolloutEvaluator';

const STATIC_FEATURE_FLAGS = {
  ENABLE_GENERATE_ALL_PREVIEWS: true,
  ENABLE_TIMELINE: true,
  ENABLE_IMPORT_MEDIA: false,
  ENABLE_WHISPER_INTEGRATION: true,
  ENABLE_AVATARS: !!new URLSearchParams(window.location.search).get('enable_avatars'),
  FORCE_DEFAULT_PRESET: true,
  ROOST: true,
};

const CONFIG_CAT_FEATURE_FLAGS = {
  ENABLE_STREAMING: { key: 'kettleStreamingEnabled', defaultValue: false },
  ENABLE_EMOTIONS: { key: 'voiceEmotionsEnabled', defaultValue: false },
  ENABLE_VOICE_ADJUSTMENTS: { key: 'voiceAdjustmentEnabled', defaultValue: false },
  ENABLE_STYLES: { key: 'voiceStylesEnabled', defaultValue: false },
  ENABLE_VOICE_CLONING_ENHANCING: { key: 'voiceCloningEnhancingEnabled', defaultValue: false },
  ENABLE_V3_VOICE_MODEL: { key: 'v3VoiceModelEnabled', defaultValue: false },
};

export type StaticFeatureFlags = typeof STATIC_FEATURE_FLAGS;

export type ConfigCatFeatureFlags = {
  [key in keyof typeof CONFIG_CAT_FEATURE_FLAGS]: (typeof CONFIG_CAT_FEATURE_FLAGS)[key]['defaultValue'];
};

export type LoadingConfigCatFeatureFlags = {
  [key in keyof typeof CONFIG_CAT_FEATURE_FLAGS]: {
    value: (typeof CONFIG_CAT_FEATURE_FLAGS)[key]['defaultValue'];
    loading: boolean;
  };
};

export type FeatureFlags = StaticFeatureFlags & ConfigCatFeatureFlags;

export function useFeatureFlags() {
  const configCatUser = useConfigCatUser();
  const loadingConfigCatFFs = {} as LoadingConfigCatFeatureFlags;
  for (const key of keys(CONFIG_CAT_FEATURE_FLAGS)) {
    const ff = CONFIG_CAT_FEATURE_FLAGS[key];
    if (!configCatUser) {
      loadingConfigCatFFs[key] = useFeatureFlag(ff.key, ff.defaultValue); // eslint-disable-line react-hooks/rules-of-hooks
    } else {
      loadingConfigCatFFs[key] = useFeatureFlag(ff.key, ff.defaultValue, configCatUser); // eslint-disable-line react-hooks/rules-of-hooks
    }
  }
  return useMemo(() => {
    const featureFlagsLoading = Object.values(loadingConfigCatFFs).some((flag) => flag.loading);
    const configCatFFs = fromEntries(entries(loadingConfigCatFFs).map(([key, ff]) => [key, ff.value]));
    const featureFlags = { ...STATIC_FEATURE_FLAGS, ...configCatFFs };
    return { featureFlags, featureFlagsLoading };
  }, [
    configCatUser,
    ...Object.values(loadingConfigCatFFs).map((f) => f.value),
    ...Object.values(loadingConfigCatFFs).map((f) => f.loading),
  ]);
}

export function useConfigCatUser() {
  const { currentUser } = useAuth();
  return !currentUser
    ? null
    : new ConfigCatUser(
        currentUser?.uid,
        currentUser?.email ?? undefined,
        undefined, // country
        {
          // userRole: user.role, // commented out because it is unavailable
          // isPro: user.isPro, // commented out because it is unavailable
        }
      );
}
